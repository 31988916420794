<template>
    <div class="qingwu">
        <div class="admin_main_block">
            <div class="admin_main_block_top">
                <div class="admin_main_block_left">
                    <div>精美头像</div>
                </div>

                <div class="admin_main_block_right">
                    <div>
                        <el-button icon="el-icon-back" @click="$router.go(-1)">返回</el-button>
                    </div>
                </div>
            </div>

            <div class="admin_form_main">
                <el-form label-width="100px" ref="info" :model="info">

                    <el-form-item label="名称" prop="F_VR_MINGC"
                                  :rules="[{required:true,message:'名称不能为空',trigger: 'blur' }]">
                        <el-input placeholder="请输入名称" v-model="info.F_VR_MINGC"></el-input>
                    </el-form-item>
                    <!--<el-form-item label="音乐" prop="F_VR_YINY"
                                  :rules="[{required:true,message:'音乐不能为空',trigger: 'blur' }]">
                        <el-input placeholder="请输入音乐" v-model="info.F_VR_YINY"></el-input>
                    </el-form-item>-->

                    <el-form-item label="分类名称" prop="F_VR_MINGC">
                        <el-select style="width:300px" v-model="info.F_IN_FENLID" placeholder="请选择分类名称"
                                   @change="selectChange()">
                            <el-option label="请选择分类名称" :value="0"></el-option>
                            <el-option v-for="(v,k) in info['fenl']" :label="v.F_VR_FENLMC" :key="k"
                                       :value="v.F_IN_ID"></el-option>
                        </el-select>
                    </el-form-item>

                    <el-form-item label="封面" prop="thumb">
                        <el-upload class="avatar-uploader" :action="$api.advUpload" :headers="upload_headers"
                                   :show-file-list="false" :on-success="handleAvatarSuccess">
                            <img v-if="info.F_VR_FENGM" :src="info.F_VR_FENGM" class="avatar-upload">
                            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                        </el-upload>
                    </el-form-item>



                    <el-form-item label="上传视频一">
                        <el-upload
                                :on-success="handleVodUploadSuccessone"
                                :on-remove="handleVodRemove"
                                :before-remove="beforeVodRemove"
                                :on-exceed="handleUploadExceed"
                                :file-list="fileList"
                                :action="$api.shipUpload"
                                :headers="upload_headers"
                                :limit="1"
                                class="upload-demo">
                            <el-button size="small" type="primary">上传视频</el-button>
                            <el-tooltip placement="right-end">
                                <div slot="content">最大支持1G，<br>
                                    支持3GP、ASF、AVI、DAT、DV、FLV、F4V、<br>
                                    GIF、M2T、M4V、MJ2、MJPEG、MKV、MOV、MP4、<br>
                                    MPE、MPG、MPEG、MTS、OGG、QT、RM、RMVB、<br>
                                    SWF、TS、VOB、WMV、WEBM 等视频格式上传</div>
                                <i class="el-icon-question"/>
                            </el-tooltip>
                        </el-upload>
                    </el-form-item>





                    <el-form-item>
                        <el-button type="primary" @click="submitForm('info')">提交</el-button>
                        <el-button @click="resetForm('info')">重置</el-button>
                    </el-form-item>
                </el-form>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        components: {},
        props: {},
        data() {
            return {
                edit_id: 0,
                info: {},
                roles_list: [],
                roles: [],
                upload_headers: {},


                file_list:[],
                file_list2:[], // element 内置file_list
                dialogVisible:false,// 是否打开预览
                dialogImageUrl:'', // 预览
                disabled:false,
                video: {// 课时对象
                    title: '',
                    sort: 0,
                    free: false,
                    videoSourceId: '',
                    videoOriginalName: ''
                },
            };
        },
        watch: {},
        computed: {},
        methods: {
            resetForm: function (e) {
                this.$refs[e].resetFields();
            },
            submitForm: function (e) {
                let _this = this;

                // 验证表单
                this.$refs[e].validate(function (res) {
                    if (res) {
                        //  判断是Add 或者 Edit
                        let url = _this.$api.addTgDongtbzlb;
                        if (_this.edit_id > 0) {
                            url = _this.$api.editTgDongtbzlb + _this.edit_id;
                        }
                        // Http 请求
                        _this.$post(url, _this.info).then(function (res) {
                            if (res.code == 200) {
                                _this.$message.success("编辑成功");
                                _this.$router.go(-1);
                            } else {
                                _this.$message.error("编辑失败");
                            }
                        });
                    }
                });
            },
            //成功回调
            handleVodUploadSuccessone(response, file, fileList) {
                this.video.videoSourceId = response.data.videoId
                this.video.videoOriginalName = file.name;
                this.info.F_VR_TUP = response.data;
                console.log(response)
                console.log(response.data.videoId)
                console.log(file.name)

            },
            //修改回掉
            editVideo(videoId) {
                this.dialogVideoFormVisible = true
                video.getVideoInfoById(videoId).then(response => {
                    this.video = response.data.item
                    this.fileList = [{'name': this.video.videoOriginalName}]
                })
            },
            //视图上传多于一个视频
            handleUploadExceed(files, fileList) {
                this.$message.warning('想要重新上传视频，请先删除已上传的视频')
            },

            beforeVodRemove(file, fileList) {
                return this.$confirm(`确定移除 ${file.name}？`)
            },
            handleVodRemove(file, fileList) {
                console.log(file)
                vod.removeById(this.video.videoSourceId).then(response=>{
                    this.$message({
                        type: 'success',
                        message: response.message
                    })
                })
            },

            get_users_info: function () {
                let _this = this;
                this.$get(this.$api.editTgDongtbzlb + this.edit_id).then(function (res) {
                    _this.info = res.data;
                })
            },
            get_adv_list: function () {
                let _this = this;
                this.$get(this.$api.addTgDongtbzlb).then(function (res) {
                    _this.info = res.data;
                })

            },
            handleAvatarSuccess(res) {
                this.info.F_VR_FENGM = res.data;
                this.$forceUpdate();
            },
            handleAvatarSuccesss(res) {
                this.info.F_VR_DINGBT = res.data;
                this.$forceUpdate();
            },
        },
        created() {
            this.upload_headers.Authorization = 'Bearer ' + localStorage.getItem('token'); // 要保证取到
            // 判断是否是编辑
            if (!this.$isEmpty(this.$route.params.F_IN_ID)) {
                this.edit_id = this.$route.params.F_IN_ID;
            }
            if (this.edit_id > 0) {
                this.get_users_info();
            } else {
                this.get_adv_list();
            }
        },
        mounted() {
        }
    };
</script>
<style lang="scss" scoped>

</style>